<template>
  <router-view />
  <g-dialog-root />
</template>

<script>
  import { GDialogRoot } from "gitart-vue-dialog";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      GDialogRoot,
    },
    computed: {
      ...mapGetters({
        getScrollState: "screen/getScrollState",
      }),
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleResize);
    },

    async created() {
      await this.$store.dispatch("session/recoverSession");
    },

    methods: {
      ...mapActions({
        setScrollState: "screen/setScrollState",
        setClientSize: "screen/setClientSize",
      }),

      handleScroll() {
        let pos = Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        );

        if (pos < 50 && this.getScrollState) {
          this.setScrollState(false);
        }

        if (pos >= 50 && !this.getScrollState) {
          this.setScrollState(true);
        }
      },

      handleResize() {
        this.setClientSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      },
    },
  };
</script>

<style>
  .vs__clear {
    transform: scale(0.8);
  }

  .vs__selected-options {
    padding: 3px 2px !important;
  }
</style>
